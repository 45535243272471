import React, { Component } from 'react'

export default class Input extends Component {
    iconSelect = () => {
      return (
        <div className='select-icon'>
          <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.374196 0.382399C0.613866 0.137549 0.938885 0 1.27778 0C1.61667 0 1.94169 0.137549 2.18136 0.382399L8.50772 6.84743L14.8341 0.382399C15.0751 0.144488 15.398 0.0128435 15.7331 0.0158193C16.0682 0.018795 16.3887 0.156153 16.6257 0.398309C16.8626 0.640464 16.997 0.968042 17 1.31049C17.0029 1.65294 16.874 1.98285 16.6412 2.22918L9.4113 9.6176C9.17163 9.86245 8.84661 10 8.50772 10C8.16882 10 7.8438 9.86245 7.60413 9.6176L0.374196 2.22918C0.134599 1.98425 0 1.65211 0 1.30579C0 0.959466 0.134599 0.627323 0.374196 0.382399Z" fill="#ffffff"/>
          </svg>
        </div>
      )
    };
    checkboxIcon = () => {
      return (
        <div className="checkbox-icon">
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.2635 11.175C5.13017 11.175 5.00517 11.154 4.8885 11.112C4.77183 11.0707 4.6635 11 4.5635 10.9L0.263502 6.6C0.0801684 6.41667 -0.00749826 6.179 0.000501742 5.887C0.00916841 5.59567 0.105168 5.35833 0.288501 5.175C0.471835 4.99167 0.705168 4.9 0.988502 4.9C1.27183 4.9 1.50517 4.99167 1.6885 5.175L5.2635 8.75L13.7385 0.275C13.9218 0.0916668 14.1595 0 14.4515 0C14.7428 0 14.9802 0.0916668 15.1635 0.275C15.3468 0.458333 15.4385 0.695667 15.4385 0.987C15.4385 1.279 15.3468 1.51667 15.1635 1.7L5.9635 10.9C5.8635 11 5.75517 11.0707 5.6385 11.112C5.52183 11.154 5.39683 11.175 5.2635 11.175Z" fill="#999999"/>
          </svg>
        </div>
      )
    }

    render() {
      const { label, type, onChange, value = [], required } = this.props;

      const selectIcon = this.iconSelect();
      const checkboxIcon = this.checkboxIcon();

      return (
        <div className={(type === 'checkbox') ? "input-item checkbox-item" : "input-item"}>
          <label>
            {(!!label && type !== 'checkbox') ? (<div className={'input-item__label ' + (type === 'select' ? 'input-item__label--big' : '')}>{label}</div>) : (null)}
            {type === 'select' ? (
              <>
                {/* <Select
                  className={classNames('class1','class2','class3','class4','class5','class6')}
                  defaultValue={newOptions[0]}
                  options={newOptions}
                  onChange={onChange}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 6,
                    // borderColor: '#B5BEC7',
                    borderColor: 'transparent',
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(49, 102, 100, 0.33)',
                      primary75: 'orange',
                      primary: '#B5BEC7',
                      // borderColor: '#B5BEC7',
                    },
                  })}
                /> */}
                <select onChange={onChange}>
                  {value.map((value, i) =>
                    <option key={i} value={value}>{value}</option>
                  )}
                </select>
                {selectIcon}
              </>
            ) : (
              (type === 'checkbox') ? (
                <>
                  <input
                    type='checkbox'
                    value={value}
                    onChange={onChange}
                    required={required}
                  />
                  <div className='input-item__checkbox'>
                    {value ? checkboxIcon : null}

                  </div>
                  <div className='input-item__checkbox-name'>{label}</div>
                </>
              ) : (
                <input
                  type={type || 'text'}
                  value={value}
                  onChange={onChange}
                  required={required}
                />
              )
            )}
          </label>
        </div>
      )
    }
}
